import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export let nav;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('going-up'), 40);
        }

    } else {
        enableScrollLock();
        nav.classList.add('nav--open');
    }
}

export function triggerScroll() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');
    }
}

export function setupNav(selector = '.nav') {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');
        const navLinks = nav.querySelectorAll('.nav__menu a');

        if (navBtn) {
            navBtn.addEventListener('click', toggleMenuOpen);
        }

        Array.from(navLinks).forEach(navLink => {
            navLink.addEventListener('click', triggerScroll);
        });

        stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
    }
}
