import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

/**
 * Default element selector is data-action^="slider"
 *
 * @param {HTMLElement} container
 * @constructor
 */
export class Slider {
    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */
    constructor(container) {
        this.container = container;
        const slider = this.container.querySelector('.swiper');

        if (slider) {

            const gallery = new Swiper(slider, {
                slidesPerView: 1.5,
                spaceBetween: 30,
                breakpoints: {
                    999: {
                        slidesPerView: 2.5
                    }
                },
                loop: true,
                loopedSlides: 3,
                slideToClickedSlide: true,
                navigation: {
                    prevEl: this.container.querySelector('.btn__prev'),
                    nextEl: this.container.querySelector('.btn__next')
                }
            });

            const modalContainer = this.container.querySelector('[data-action="slider-modal"]');

            if (modalContainer) {

                const sliderModal = modalContainer.querySelector('.swiper');

                if (sliderModal) {

                    const galleryModal = new Swiper(sliderModal, {
                        spaceBetween: 30,
                        autoHeight: true,
                        loop: true,
                        loopedSlides: 3,
                        observer: true, /* needed to avoid conflicts with scrollLock and resizing */
                        observeParents: true, /* needed to avoid conflicts with scrollLock and resizing */
                        navigation: {
                            prevEl: modalContainer.querySelector('.btn__prev'),
                            nextEl: modalContainer.querySelector('.btn__next')
                        },
                        thumbs: {
                            swiper: gallery
                        }
                    });

                    galleryModal.on('slideChangeTransitionStart', function() {
                        gallery.slideTo(galleryModal.activeIndex);
                    });

                    gallery.on('transitionStart', function() {
                        galleryModal.slideTo(gallery.activeIndex);
                    });
                }
            }
        }

    }
}

export function setupSlider(selector = '[data-action="slider"]') {

    const sliders = document.body.querySelectorAll(selector);

    for (let i = 0; i < sliders.length; i++) {
        void new Slider(sliders[i]);
    }

};