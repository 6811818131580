import { onReady } from './utils/onReady';
import { isTouch, initVhUnitOverwrite } from './utils/helpers';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupIntersect } from './components/intersect';
import { setupAnchorLinkScroll } from './components/anchors';
import { setupAccordions } from './components/accordion';
import { setupSlider } from './components/slider';
import { setupOverlay } from './components/overlay';
import { watchFormFields } from './components/form';

function init() {
    document.body.classList.remove('standby');

    // Detect touch
    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    onReady(() => {
        initVhUnitOverwrite();
        setupNav();
        setupLazyLoading();
        setupIntersect();
        setupAnchorLinkScroll();
        setupAccordions();
        setupSlider();
        setupOverlay();
        watchFormFields();
    });
}

init();
