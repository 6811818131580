/**
 * Default element selector is data-action*="accordion"
 *
 * @param {HTMLElement} trigger
 * @constructor
 */
export class Accordion {
    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} trigger
     */
    constructor(trigger) {

        this.dom = {
            trigger,
            accordion: trigger,
            content: trigger.querySelector('.accordion__content')
        };

        this.dom.trigger.addEventListener('click', e => {

            if (e && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
                e.preventDefault();
            }

            // Close siblings
            const siblings = this.dom.accordion.parentNode.querySelectorAll('[data-action="accordion"]');
            Array.from(siblings).forEach(sibling => {
                if (this.dom.accordion.id !== sibling.id) {
                    closeAccordion(sibling, sibling.querySelector('.accordion__content'));
                }
            });

            // Close/open clicked accordion
            if (!this.dom.accordion.classList.contains('js-closed')) {
                closeAccordion(this.dom.accordion, this.dom.content);
            } else {
                openAccordion(this.dom.accordion, this.dom.content);
            }
        });
    }
}

function openAccordion(accordion, content) {
    accordion.classList.remove('js-closed');
    accordion.setAttribute('aria-expanded', true);
    content.setAttribute('aria-hidden', false);
}

function closeAccordion(accordion, content) {
    accordion.classList.add('js-closed');
    accordion.setAttribute('aria-expanded', false);
    content.setAttribute('aria-hidden', true);
}

/**
 * Initialise Accordions with this function
 * Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */

export function setupAccordions(selector = '[data-action="accordion"]') {
    const accordions = document.querySelectorAll(selector);
    if (accordions) {
        for (let i = 0; i < accordions.length; i++) {
            void new Accordion(accordions[i]);
        }
    }
}